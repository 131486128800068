@import 'c/main/src/constants';
@import 'c/main/src/type';

.blue-line {
  flex-basis: $column;
  height: 100vh;
  background: $blue;

  &__container {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    z-index: 1;

    @include desktop-and-tablet {
      position: fixed;
      left: $navbar;
      width: $navbar * 10;
    }
  }

  &--1 {
    opacity: 0.9;
  }
  &--2 {
    opacity: 0.8;
  }
  &--3 {
    opacity: 0.7;
  }
  &--4 {
    opacity: 0.6;
  }
  &--5 {
    opacity: 0.5;
  }
  &--6 {
    opacity: 0.4;
  }
  &--7 {
    opacity: 0.3;
  }
  &--8 {
    opacity: 0.2;
  }
  &--9 {
    opacity: 0.1;
  }
}

.standard-carousel {
  &:after {
    content: 'flickity';
    display: none; /* hide :after */

    @include desktop-and-tablet {
      content: '';
    }
  }

  .flickity-page-dots .dot {
    background: $blue;
    &.is-selected {
      background: $blue;
    }
  }
}


.play-video {
  color: $white;
 
  @include desktop-and-tablet {
    @include Neue(45px, 45px * $font-min, null);
   
  }

  a {
    display: flex;
    align-items: center;
    height: 100%;
    margin-top: 0.15em;

    &:hover {
      color: $white;
      opacity: 0.8;
    }
  }

  svg {
    width: auto;
    height: 100%;
   
    path {
      fill: $white!important;
    }
    circle {
      stroke: $white!important;
    }
  }
}

.hero .play-video {
  position: absolute;
  bottom: $padding;
  left: unset;
  right: $padding;
  color: $white;
  
  @include desktop-and-tablet {
    right: calc(100vw + #{$padding});
  }
  a {
    margin-top: 0;
    margin-left: 0;
  }
  a:hover {
    color: $white;
    opacity: 0.8;
  }

  svg {
    margin-left: 1ch;
    path {
      fill: $white!important;
    }
    circle {
      stroke: $white!important;
    }
  }
}

.video-wrapper {
  
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  background: $white;
  z-index: 10;
  display: none;
  

  @include desktop-and-tablet {
   
    top: $padding;
    left: $padding * 3;
    width: calc(100vw - #{$padding} * 4);
    bottom: $padding;
  }

  &.active {
    display: block;
  }

  .close {
    position: absolute;
    top: $padding;
    right: $padding;
    z-index: 100;
    color: $white;
    cursor: pointer;
  }

  .video-player {
    width: 100%;
    height: 100%;
    display: block;

    source {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
}


.homepage {
  color: $blue;

  .content_block {
    display: block;
    position: relative;
    scroll-snap-align: start;
    margin-bottom: 10em;
    background: $white;

    @include desktop-and-tablet {
      margin-bottom: 0;
    }

    &__title {
      margin: 0 $column;
      padding: 1em 0 5em;
    }

    &__subtitle {
      margin: 0;
      position: absolute;
      top: 9em;
      left: 0;
      width: 100%;
      z-index: 2;

      @include desktop-and-tablet {
        top: 6em;
        left: 1em;
        display: flex;
        justify-content: center;
        height: calc(100vh - 12em);
        width: calc(100vh - 12em);
      }

      .mobile-only {
        text-align: center;
      }

      svg {
        max-width: 100%;
        height: auto;
        color: $blue;
        fill: $blue;
        line-height: 1em;
        width: auto;
        position: relative;

        @include desktop-and-tablet {
          max-height: calc(100vh - 12em);
          height: 100%;
          width: auto;
          max-width: none;
          transform: translate3d(0, 0, 0);
        }
      }
    }

    &__detail {
      margin: 5em $column 0;
      width: 66% - $column;

      @include desktop-and-tablet {
        margin: 5em $column;
      }
    }
  }

  .parallax__layer--base {
    display: flex;
    flex-direction: column;

    @include desktop-and-tablet {
      height: 100vh;
    }
  }

  .hero {
    position: relative;
    height: 100vh;

    @include desktop-and-tablet {
      padding-left: $navbar;
      width: 200vw;
      overflow: hidden;
    }

    &.content_block {
      background: transparent;
    }

    .content_block {
      &__subtitle {
        @include phone {
          margin: 0 $padding;
          width: calc(100vw - #{$padding * 2});
        }
      }
      &__title {
        color: $white;
        margin-left: $column;
        margin-top: 0;
        margin-bottom: 1em;
        padding: 1em 0;
        @include Neue(22px);
        position: relative;
        z-index: 2;
        height: 10rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        @include desktop-and-tablet {
          height: 11rem;
          @include Neue(23px, 23px * $font-min, null);
          background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 1)
          );
          margin: 0;
          width: $navbar * 10;
          justify-content: center;
          padding-left: $navbar;
        }
        p {
          margin: 0;
        }
      }
    }
  }

  #experience {
    .parallax__layer--base {
      display: flex;
      flex-direction: column;

      @include desktop-and-tablet {
        flex-direction: column;
        flex-wrap: wrap;
        padding: $padding 0;

        @supports (display: grid) {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: minmax(4rem, 1fr) 2fr 2fr;
        }
      }
    }

    .content_block__subtitle {
      top: 7em;
      @include desktop-and-tablet {
        top: 5em;
        margin: 0 0 0 $column;
      }
    }

    .content_block__title {
      order: 1;
      @include desktop-and-tablet {
        flex-basis: 20vh;
        margin: 0;
        padding: 0 $column / 4 $padding;
        width: 50%;

        @supports (display: grid) {
          grid-column: 1 / 2;
          grid-row: 1 / 2;
          width: auto;
        }
      }
    }

    .content_block__detail {
      order: 3;

      @include desktop-and-tablet {
        width: 25%;
        flex-grow: 1;
        margin: 0 0 0 $column;
        padding: $padding;
        border-left: 1px solid $blue;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @supports (display: grid) {
          grid-column: 2 / 3;
          grid-row: 1 / 3;
          width: 50%;
        }
      }
    }

    .content_block__image {
      order: 2;
      width: 66%;

      @include desktop-and-tablet {
        flex-basis: 80vh;
        width: 50%;
        padding: $padding / 2 $column / 2;
        max-height: 80vh;

        @supports (display: grid) {
          grid-column: 1 / 2;
          grid-row: 2 / 3;
          width: 100%;
        }
      }

      > .image {
        @include desktop-and-tablet {
          width: 100%;
          max-width: calc(60vh - #{$padding});
        }
      }
    }

    .content_block__image + .content_block__image {
      order: 4;
      width: 66%;
      align-self: flex-end;
      @include desktop-and-tablet {
        flex-shrink: 1;
        width: 50%;
        padding: 0 $column 0 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        @supports (display: grid) {
          grid-column: 2 / 3;
          grid-row: 3 / 4;
          width: 100%;
        }
      }

      > .image {
        @include desktop-and-tablet {
          width: 100%;
          max-width: calc(60vh - #{$padding});
        }
      }
    }
  }

  #sense {
    .parallax__layer--base {
      display: flex;
      flex-direction: column;

      @include desktop-and-tablet {
        flex-wrap: wrap;
        padding: $padding 0;

        @supports (display: grid) {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: minmax(20%, 1fr) 1fr;
        }
      }
    }

    .content_block__title {
      order: 1;

      @include desktop-and-tablet {
        flex-basis: 100vh;
        margin-left: 0;
        margin-top: 0;
        padding: 0 $padding;
        width: 50%;
        border-left: 1px solid $blue;

        @supports (display: grid) {
          grid-column: 1 / 2;
          grid-row: 1 / 2;
        }
      }
    }

    .content_block__subtitle {
      top: 5em;
      @include desktop-and-tablet {
        top: 6em;
        margin-left: $padding * 2;
        left: 25%;
      }
      svg {
        overflow: visible;
      }
    }

    .content_block__detail {
      order: 3;
      width: 80% - $column;

      @include desktop-and-tablet {
        order: 2;
        margin: 0;
        width: 100%;
        display: flex;
        border-left: 1px solid $blue;

        @supports (display: grid) {
          grid-column: 1 / 2;
          grid-row: 2 / 3;
        }
      }

      > * {
        @include desktop-and-tablet {
          flex-basis: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
        }
      }

      p {
        @include desktop-and-tablet {
          padding: 0 $padding;
          margin: $padding 0 0 0;
        }
      }
    }

    .content_block__image {
      order: 2;
      width: 80%;

      @include desktop-and-tablet {
        order: 3;
        flex-basis: 100%;
        margin: auto 0 0 0;
        width: 100%;
        align-self: flex-end;

        @supports (display: grid) {
          grid-column: 2 / 3;
          grid-row: 2 / 3;
          width: auto;
        }
      }

      .image {
        @include desktop-and-tablet {
          width: 55vh;
        }
      }
    }
  }

  #location {
    @include desktop-and-tablet {
      width: calc(130vh + #{$scrollBarHeight});
    }
    .parallax__layer--base {
      display: flex;
      flex-direction: column;

      @include desktop-and-tablet {
        flex-wrap: wrap;

        @supports (display: grid) {
          display: grid;
          grid-template-columns: 2fr 4fr 1fr;
          grid-template-rows: 1fr 1fr;
        }
      }
    }

    .content_block__title {
      order: 1;

      @include desktop-and-tablet {
        flex-basis: 100vh;
        width: 40%;
        margin: $padding 0;
        padding: 0 $padding;
        border-left: 1px solid $blue;
        @supports (display: grid) {
          grid-column: 1 / 2;
          grid-row: 1 / 3;
        }
      }
    }

    .content_block__subtitle {
      top: 11em;

      @include desktop-and-tablet {
        top: 8em;
        left: 4em;
        transform: translateX(50%);
      }

      svg {
        overflow: visible;
      }
    }

    .content_block__image {
      order: 2;
      width: 80%;
      margin: 0 $column $column * 2.5;

      @include desktop-and-tablet {
        width: 90%;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-bottom: $padding / 2;

        @supports (display: grid) {
          grid-column: 2 / 3;
          grid-row: 1 / 2;
        }
      }

      > * {
        @include desktop-and-tablet {
          max-width: calc(75vh - #{$padding * 2});
          padding: 0;
        }
      }
    }

    .content_block__image + .content_block__image {
      @include desktop-and-tablet {
        justify-content: flex-start;
        padding-top: $padding / 2;

        @supports (display: grid) {
          grid-row: 2 / 3;
        }
      }
    }
  }

  #map {
    @include desktop-and-tablet {
      min-height: 80vw;
      width: calc(180vh + #{$scrollBarHeight});
      // width: calc(110vh + #{$scrollBarHeight});
    }
    .parallax__layer--base {
      @include desktop-and-tablet {
        flex-wrap: wrap;
      }

      &::after {
        @include desktop-and-tablet {
          content: ('');
          width: 10%;
        }
      }
    }

    .map-wrap {
      margin: 0 $column;
      @include desktop-and-tablet {
        flex-basis: calc(100vh - #{$padding * 2});
        width: 36%;
        margin: $padding * 4 auto $padding 0;
        // width: 55%;
        // margin: $padding * 4 auto $padding;
      }

      .map {
        position: relative;
        height: calc(80vh - #{$padding * 4});

        @include desktop-and-tablet {
          height: calc(100vh - #{$padding * 5});
        }

        .background.map-canvas {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
        }
      }
    }

    .map {
      &__container {
        display: flex;

        @include desktop-and-tablet {
          flex-basis: 100vh;
          // width: 25%;
          width: 15%;
          align-self: flex-end;
          flex-direction: column;
          margin: $padding $padding $padding auto;
          // margin: $padding;
          flex-wrap: wrap;
          justify-content: flex-end;
        }
      }

      &__content {
        margin: 0 $column $padding;
        @include desktop-and-tablet {
          width: 100%;
          margin: 0;
          @include Neue(
            $body-font-med,
            $body-font-min,
            null //$body-font-med * $font-max
          );
        }

        p {
          &:last-of-type {
            margin-bottom: 0;
          }
        }

        strong {
          @include NeueRegular($body-font-min);

          @include desktop-and-tablet {
            @include NeueRegular(
              $body-font-med,
              $body-font-min,
              null //$body-font-med * $font-max
            );
          }
        }
      }
    }
  }

  #site {
    @include tablet {
      min-height: 180vw;
    }
    @include tablet-landscape {
      min-height: 150vw;
    }
    @include desktop {
      min-height: 120vw;
    }
    .content_block__subtitle {
      top: 0;
      margin-top: 0;

      @include desktop-and-tablet {
        margin: $padding * 3 0 0 0;
        transform: translateX(-25%);
        z-index: 1;
      }
    }

    svg {
      overflow: visible;
    }

    .parallax__layer--base {
      display: flex;
      flex-direction: column;

      @include desktop-and-tablet {
        flex-wrap: wrap;
        align-content: flex-end;
        padding: $padding 0;

        @supports (display: grid) {
          display: grid;
          grid-template-columns: 2fr 1fr 1fr;
          grid-template-rows: 1fr 1fr max-content;
        }
      }
    }

    .content_block__title {
      order: 2;
      margin: 1em $column;
      padding-bottom: 0;

      @include desktop-and-tablet {
        // align-self: flex-end;
        margin: 0 0;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        @supports (display: grid) {
          grid-column: 2 / 3;
          grid-row: 1 / 3;
        }
      }
    }
    .content_block__image {
      order: 1;
      padding-top: 0;
      margin: 1em $column;

      @include desktop-and-tablet {
        flex-basis: calc(100vh - #{$padding * 4});
        width: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-top: $padding * 2;
        margin-bottom: 0;
        padding: 0;
        overflow: hidden;
        align-items: flex-end;

        @supports (display: grid) {
          grid-column: 1 / 2;
          grid-row: 1 / 4;
        }
      }

      .image {
        @include desktop-and-tablet {
          width: calc(66.6vh - #{$padding * 2});
        }
      }
    }
    .content_block__detail {
      order: 3;
      padding-top: 0;
      width: 70%;
      margin-top: 2em;

      @include desktop-and-tablet {
        width: 25%;
        align-self: flex-end;
        margin: 0;
      }

      &--left {
        margin-bottom: 0;
        @include desktop-and-tablet {
          padding-right: $padding;

          @supports (display: grid) {
            grid-column: 2 / 3;
            grid-row: 3 / 4;
            width: auto;
          }
        }
      }

      &--right {
        margin-top: 0;
        @include desktop-and-tablet {
          flex-basis: calc(100vh - #{$padding * 2});
          border-left: 1px solid $blue;
          padding-left: $padding;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;

          @supports (display: grid) {
            grid-column: 3 / 4;
            grid-row: 1 / 4;
            width: auto;
            height: 100%;
          }
        }
      }
      p {
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  #lot-diagram {
    @include desktop-and-tablet {
      width: calc(180vh + 25px);
      // overflow-x: hidden;
    }

    .parallax__layer--base {
      @include desktop-and-tablet {
        margin: $padding $column;
        border-left: 1px solid $blue;
        position: relative;
        height: calc(100vh - #{$padding * 2});
      }
    }
    .content_block {
      &__title {
        padding-bottom: 0;

        @include desktop-and-tablet {
          margin: 0;
          padding: 0 $padding;
        }
      }

      &__detail {
        margin: 0 $column;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;

        @include desktop-and-tablet {
          margin: 0;
          padding: 0 $padding;

          @include Neue(
            $body-font-med,
            $body-font-min,
            null // $body-font-med * $font-max
          );
        }

        p {
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }

      &__image {
        margin: $padding 0 14em 0;
        overflow-x: hidden;

        @include desktop-and-tablet {
          margin: 0;
          padding: 0 $padding;
          overflow: hidden;
        }

        > * {
          width: 200%;
          margin: 0;

          @include desktop-and-tablet {
            width: calc(145vh);
          }
        }
        .image {
          transition: transform 300ms cubic-bezier(0.14, 0.76, 0.25, 1.06);
        }
      }
    }
  }

  #team {
    @include desktop-and-tablet {
      overflow: hidden;
    }
    .parallax__layer--base {
      display: flex;
      flex-direction: column;

      @include desktop-and-tablet {
        flex-wrap: wrap;
        padding: $padding 0;
        margin: 0 $column 0 $column / 2;

        @supports (display: grid) {
          display: grid;
          grid-template-columns:
            minmax(250px, 1fr) minmax(250px, 1fr) minmax(250px, 1fr)
            1fr;
          grid-template-rows: max-content 1fr 1fr;
        }
      }
    }

    .content_block__title {
      order: 1;
      margin: 1em $column;
      padding-bottom: 0;

      @include desktop-and-tablet {
        margin: 0;
        padding: 0 0 $padding $padding;
        border-left: 1px solid $blue;
        width: 25%;

        @supports (display: grid) {
          grid-column: 1 / 2;
          grid-row: 1 / 2;
          width: auto;
        }
      }
    }

    .standard-carousel {
      order: 2;
      padding-top: 0;
      margin: 0;

      @include desktop-and-tablet {
        flex-basis: calc(100vh - #{$padding * 2});
        display: flex;
        flex-direction: column;
        width: 50%;
        margin: 0 $column;
        justify-content: space-between;
        order: 4;
        max-height: calc(100vh - #{$padding * 2});

        @supports (display: grid) {
          grid-column: 4 / 5;
          grid-row: 1 / 4;
          width: auto;
        }
      }

      .slide {
        width: 100%;
        margin-right: 10%;
      }

      .content_block__image {
        @include desktop-and-tablet {
          width: calc(70vh - #{$padding * 4});
        }

        &--caption {
          padding-bottom: $padding / 2;
          padding-left: $column;
          @include Neue(14px);
          @include desktop-and-tablet {
            padding-left: 0;
            padding-bottom: $padding / 2;
          }
        }
      }
    }
    .content_block__detail {
      order: 3;
      padding-top: 0;
      width: 70%;

      @include desktop-and-tablet {
        order: 2;
        margin: 0;
        padding: 0 $padding * 1.5 0 $padding;
        border-left: 1px solid $blue;
        width: 35%;
        flex-grow: 1;
        // font-size: 14px;

        @supports (display: grid) {
          grid-column: 1 / 2;
          grid-row: 2 / 4;
          width: auto;
        }
      }

      p {
        &:first-of-type {
          margin-top: 0;
        }
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    .content_block__textblock {
      order: 4;
      padding-top: 0;
      margin: 0 $column;

      @include desktop-and-tablet {
        order: 3;
        margin: 0;
        padding: 3.5em $padding 0 $padding;
        border-left: 1px solid $blue;
        width: 35%;
        justify-self: flex-end;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        @supports (display: grid) {
          grid-column: 2 / 3;
          grid-row: 1 / 4;
          width: 100%;
        }
      }

      p {
        &:first-of-type {
          margin-top: 0;
        }
        &:last-of-type {
          margin-bottom: 0;
        }
      }

      strong {
        @include NeueRegular($body-font-min);
        // font-weight: 400;
        margin: 0;

        @include desktop-and-tablet {
          @include NeueRegular(17px, 14px, null);
        }
      }
    }

    .content_block__textblock + .content_block__textblock {
      @include desktop-and-tablet {
        margin: 0;
        width: 20%;
        justify-content: flex-end;
        padding: 0 $padding;
        @include Neue(18px, 14px, null);

        @supports (display: grid) {
          grid-column: 3 / 4;
          grid-row: 1 / 4;
          width: 100%;
        }
      }

      strong {
        @include desktop-and-tablet {
          @include NeueRegular(18px, 14px, null);
        }
      }
    }
  }

  #design {
    @include desktop-and-tablet {
      $scrollBarHeight: 25px;
      width: calc(150vh + #{$scrollBarHeight});
    }

    .parallax__layer--base {
      display: flex;
      flex-direction: column;

      @include desktop-and-tablet {
        flex-wrap: wrap;
        padding: $padding 0;
        margin: 0 $column;

        @supports (display: grid) {
          display: grid;
          grid-template-columns: 1fr 2fr;
        }
      }
    }

    .content_block__title {
      order: 1;
      margin: 1em $column;
      padding-bottom: 0;

      @include desktop-and-tablet {
        margin: 0;
        padding: 0 0 $padding $padding;
        border-left: 1px solid $blue;
        width: 50%;
        @supports (display: grid) {
          grid-column: 1 / 2;
          width: auto;
        }
      }
    }

    .content_block__detail {
      order: 4;
      padding-top: 0;
      width: 70%;
      margin-bottom: 0;

      @include desktop-and-tablet {
        order: 2;
        margin: 0;
        padding: 0 $padding;
        border-left: 1px solid $blue;
        width: 33%;
        flex-grow: max;
        flex-basis: 80%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        @supports (display: grid) {
          grid-column: 1 / 2;
          grid-row: 2 / 4;
          width: auto;
        }
      }

      p {
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    .content_block__image {
      order: 2;
      padding-top: 0;
      margin: 0 $column $padding;

      @include desktop-and-tablet {
        flex-basis: 50vh;
        display: flex;
        flex-direction: column;
        // width: calc(65vh - #{$padding * 3});
        margin: 0 $column;
        justify-content: flex-end;
        order: 4;

        @supports (display: grid) {
          grid-column: 2 / 3;
          grid-row: 2 / 3;
          width: auto;
        }
      }
      .image {
        @include desktop-and-tablet {
          width: calc(66vh - #{$padding * 3});
        }
      }
      &--caption {
        @include Neue(14px);

        @include desktop-and-tablet {
          padding-bottom: $padding / 2;
        }
      }
    }

    .content_block__image + .content_block__image {
      order: 3;
      padding-top: 0;
      margin: 0 $column;

      @include desktop-and-tablet {
        flex-basis: 50vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        order: 4;

        @supports (display: grid) {
          grid-column: 2 / 3;
          grid-row: 3 / 4;
          width: auto;
        }
      }
    }

    .content_block__subtitle {
      top: 7em;
      pointer-events: none;

      @include desktop-and-tablet {
        top: 1em;
        margin-left: 30%;
      }
    }
  }

  #construction {
    @include desktop-and-tablet {
      width: 95vw;
    }
    .parallax__layer--base {
      display: flex;
      flex-direction: column;

      @include desktop-and-tablet {
        padding: $padding 0;
        margin: 0 0 0 $column;
      }
    }

    .content_block__title {
      order: 1;
      margin: 1em $column;
      padding-bottom: 0;

      @include desktop-and-tablet {
        margin: 0 0 0 0;
        padding: 0 0 $padding $padding;
        width: 25%;
        border-left: 1px solid $blue;
      }
    }

    .content_block__detail {
      order: 3;
      padding-top: 0;
      width: 70%;
    }

    .standard-carousel {
      order: 2;
      padding-top: 0;
      margin: 0;

      @include desktop-and-tablet {
        border-left: 1px solid $blue;
        padding: 0 0 0 0;
        margin: 0;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }

      &:after {
        content: 'flickity';
        display: none; /* hide :after */

        @include desktop-and-tablet {
          content: 'flickity';
        }
      }

      .flickity-page-dots {
        @include desktop-and-tablet {
          display: none;
        }
      }

      .slide {
        width: 100%;
        margin-right: 10%;

        @include desktop {
          width: 90%;

          @media screen and (max-height: 450px) {
            width: 50%;
          }

          @media screen and (max-height: 600px) {
            width: 66%;
          }
        }

        @include desktop-and-tablet {
          margin-right: $padding;
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
        }

        &:first-child {
          .aspect-maintainer {
            @include desktop-and-tablet {
              margin-left: $padding;
            }
          }
        }

        .content_block__image {
          @include desktop-and-tablet {
            order: 3;
            flex-basis: calc(66.6% - #{$padding});
            margin-right: $padding;
            align-self: flex-end;
            overflow: hidden;
            object-position: 50% 50%;
          }

          &--caption {
            @include NeueRegular($body-font-min);
            min-height: 1.3em;
            margin: 0 $column;

            @include desktop-and-tablet {
              @include NeueRegular(
                $body-font-max,
                $body-font-max * $font-min,
                null //$body-font-max * $font-max
              );
              order: 1;
              padding-top: 0;
              flex-basis: 33.3%;
              margin: 0 0 0 66.6%;
            }
          }

          &--date {
            @include NeueRegular($body-font-min);
            min-height: 2.3em;
            margin: 0 $column;

            @include desktop-and-tablet {
              @include NeueRegular(
                $body-font-max,
                $body-font-min,
                null // $body-font-max * $font-max
              );
              order: 2;
              padding-top: 0;
              padding-bottom: 1em;
              flex-basis: 33.3%;
              margin: 0 0 0 66.6%;
            }
          }

          &--detail {
            margin: 0 $column;
            padding-top: 1em;
            @include desktop-and-tablet {
              order: 4;
              padding: 0 $padding 0 0;
              flex-basis: 33.3%;
              margin: 0;
            }

            p:first-of-type {
              margin-top: 0;
            }
            p:last-of-type {
              margin-top: 0;
            }
          }
        }

        .image {
          @include desktop-and-tablet {
            // width: 53vw;
            overflow: hidden;
            max-height: 70vh;
          }
        }
      }
    }

    .carousel__prev {
      display: none;
      @include desktop-and-tablet {
        display: block;
        position: absolute;
        top: 20%;
        left: calc(#{$padding} + #{$column});
        cursor: pointer;
        z-index: 2;
      }

      &.disable {
        display: none;
      }
    }

    .carousel__next {
      display: none;
      @include desktop-and-tablet {
        display: block;
        position: absolute;
        top: 20%;
        right: $padding;
        cursor: pointer;
        z-index: 2;
      }

      &.disable {
        display: none;
      }
    }

    .content_block__subtitle {
      top: 3em;
      pointer-events: none;
    }
  }

  #lot-detail {
    background: $blue-darker;
    color: $white;
    margin-bottom: 0;
    position: relative;

    @include desktop-and-tablet {
      z-index: 1;
      width: calc(80vw + 100vh);
    }

    .blue-grid {
      display: none;
      @include desktop-and-tablet {
        display: flex;

        position: absolute;
        top: 0;
        left: 0;
        background: $white;
        height: $padding;
        width: 100%;
      }

      &__box {
        background: $blue;
        opacity: 1;
        flex-basis: 10%;

        &:nth-child(1) {
          opacity: 0.9;
        }

        &:nth-child(2) {
          opacity: 0.8;
        }
        &:nth-child(3) {
          opacity: 0.7;
        }
        &:nth-child(4) {
          opacity: 0.6;
        }
        &:nth-child(5) {
          opacity: 0.5;
        }
        &:nth-child(6) {
          opacity: 0.4;
        }
        &:nth-child(7) {
          opacity: 0.3;
        }
        &:nth-child(8) {
          opacity: 0.2;
        }
        &:nth-child(9) {
          opacity: 0.1;
        }
      }
    }
    .content_block {
      &__container {
        padding-top: 1em;
        display: flex;
        flex-direction: column;

        @include desktop-and-tablet {
          height: 100vh;
          padding: $padding 0;
          margin: 0 $column;
          flex-direction: row;
          flex-wrap: wrap;
          align-content: flex-start;
        }

        .availability-note {
          @include Neue(20px);
          padding: 0 $column;

          @include desktop-and-tablet {
            padding: 0 $padding 0 0;
            @include Neue(29px, 29px * $font-min, null);
          }

          p {
            margin-top: 0;
          }
        }
      }

      &__row {
        @include desktop-and-tablet {
          display: flex;
          height: 80%;
          flex-basis: 100%;
        }
      }

      &__title {
        margin-bottom: 1em;
        padding-bottom: 1em;

        @include desktop-and-tablet {
          margin: 0;
          padding: $padding * 2 0;
          flex-basis: 100%;
          max-height: 15%;
        }
      }

      &__firstcol {
        @include desktop-and-tablet {
          display: flex;
          flex-direction: column;
          margin-right: 0;
        }
      }

      &__table {
        margin: 1em $column / 2 3em;
        width: calc(100% - #{$column});
        font-size: 12px;

        @include desktop-and-tablet {
          // flex-basis: 50vw;
          width: calc(100% - #{$padding});
          margin: 0 $padding 1em 0;
          font-size: unset;
        }

        th {
          @include NeueRegular($body-font-min);
          text-align: left;
          // padding-bottom: 1em;
          padding-right: 1ch;
          vertical-align: top;
          line-height: 1;

          @include desktop-and-tablet {
            @include NeueRegular(
              $body-font-max,
              $body-font-min,
              null // $body-font-max * $font-max
            );
            line-height: 1.2;
          }

          &:last-of-type {
            padding-right: 0;
          }
        }

        tr + tr > th {
          padding-bottom: 1em;
        }

        tr {
          a svg {
            width: auto;
            height: 16px;

            @include desktop-and-tablet {
              height: 20px;
            }
          }
          &:hover {
            td {
              background: $blue-table;
            }
          }
        }

        td {
          vertical-align: top;
          border-left: none;
          border-right: none;
          border-top: none;
          border-bottom: 1px solid $blue-table;
          padding: 2px;
        }
      }

      &__links {
        margin: 1em $column 0;
        @include Neue(20px);
        padding-bottom: 1em;
        border-bottom: 1px solid $white;
        @include desktop-and-tablet {
          padding-bottom: 0;
          flex: 1;
          margin: 0 $column 0 0;
          @include Neue(29px, 29px * $font-min, null);
          border-bottom: none;
        }

        > * {
          display: flex;
          flex-direction: column;

          a {
            flex-basis: 50%;
            cursor: pointer;
            margin-bottom: 1em;

            @include desktop-and-tablet {
              margin-bottom: 1em;
            }
          }
        }
      }

      &__contact {
        margin: 1em $column 5em;
        display: flex;
        flex-direction: column;

        @include desktop-and-tablet {
          margin: auto 0 auto 0;
          justify-content: flex-end;
          padding-left: $padding / 2;
          height: 100%;
        }

        @media screen and (min-height: 710x) {
          // flex: 3;
          // margin: auto 0 $padding auto;
        }

        > div {
          margin: 0;

          @include desktop-and-tablet {
            flex-basis: auto;
            margin: 1em 0 0 0;
          }
        }

        strong {
          @include NeueRegular($body-font-min);

          @include desktop-and-tablet {
            @include NeueRegular(
              $body-font-max,
              $body-font-min,
              null // $body-font-max * $font-max
            );
          }
        }

        p {
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
    .contact-form {
      &__title {
        @include Neue(20px);
        margin-bottom: 1em;

        @include desktop-and-tablet {
          @include Neue(29px, 29px * $font-min, null);
        }
      }
      &__container {
        margin: 3em $column;
        padding-bottom: 3em;
        border-bottom: 1px solid $white;

        @include desktop-and-tablet {
          border-bottom: none;
          margin: 0 $column 0 0;
          width: 300px;
          padding-bottom: 0;
          padding-left: 10px;
        }

        @media screen and (min-height: 710px) {
          // flex: 2;
          // margin: 0 $column 0 auto;
        }
        .form-field {
          padding-bottom: 0.5em;

          &.message {
            label {
              padding: 0.12em 0 0.5em;
            }
          }
        }
        label {
          display: block;
          text-transform: capitalize;
        }
        input {
          width: 100%;
          border-bottom: 1px solid $white;

          @include desktop-and-tablet {
            width: 300px;
          }
        }

        .text-wrap,
        .email-wrap {
          width: 100%;
        }

        textarea {
          width: 100%;
          height: 100px;
          border: 1px solid $white;
          margin-bottom: 0.5em;

          @include desktop-and-tablet {
            width: 300px;
          }
        }

        input[type='submit'] {
          border: 1px solid $white;
          width: auto;
          padding: 0.5em 3em;

          &:hover,
          &:focus {
            background: $white;
            color: $blue;
          }
        }
      }
    }
  }
}
